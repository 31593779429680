const env = process.env.REACT_APP_ENVIRONMENT || "dev";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const envConfig = require(`./config.${env}`);

const rootConfig = {
    accessTokenName: "access_token",
    supportEmailAddress: "IIQsupport@itm21st.com",
    supportLinkToQuestionsPDF: "https://itm21st.com/wp-content/uploads/2019/07/FAQ_20190731.pdf",
    linkToManagedGuidePDF: "https://itm21st.com/IIQ_Guide_Managed",
    linkToStandardGuidePDF: "https://itm21st.com/IIQ_Guide_Standard",
    supportPhoneNumber: "866-384-2766",
    froalaKey: "WE1B5dG3H3H3A7B10B7cWHNGGDTCWHIg1Ee1Oc2Yc1b1Lg1POkB6B5F5C4F3D3I3F2B6B4==",
    litcoOrganizationID: 244
};

const config: {
    accessTokenName: string;
    apiUrl: string;
    auth0: {
        audience: string;
        clientId: string;
        domain: string;
    };
    authenticateApiUrl: string;
    databrokerApiUrl: string;
    env: "localhost" | "dev" | "uat" | "prod";
    fileApiUrl: string;
    supportEmailAddress: string;
    supportLinkToQuestionsPDF: string;
    linkToManagedGuidePDF: string;
    linkToStandardGuidePDF: string;
    supportPhoneNumber: string;
    trustReportApiUrl: string;
    froalaKey: string;
    litcoOrganizationID: number;
    innovestApiUrl: string;
    financeApiUrl: string;
    portalUrl: string;
} = { ...rootConfig, ...envConfig };

export default config;
